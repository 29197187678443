.popular-item{
 display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
}
img{
  margin-top: 1rem;
  height: 20vh;
  display:block;
  width: 70%;
  object-fit: cover;
  border-radius: 1rem;
}
p{
  font-size: 1.5rem;
 } 

h2{
  margin-top: 2rem;
  font-size: 1.5rem;

 
}
.filter-container button{
  margin-top: 4rem;
  margin-right: 2rem;
  min-width: 5rem;
  padding: 0.5rem 1rem;
  border: none;
  background: white;
  color: rgb(65,98,168);
  border-radius: 1rem;
  border:2px solid rgb(65,98,168);
  font-weight: bold;
  cursor: pointer;
}
button.active{
  background-color: rgb(65,98,168);
  color: white;

}